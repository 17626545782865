import React, { useState } from 'react';

import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Modal,
    ModalBody,
    ModalHeader,

} from 'reactstrap';

const FarmerDailyCard = ({data, ...props}) => {
    const [profileModal, setProfileModal] = useState(false);

    return(
        <>
            <Col md={3} className='mb-2'>
                <Card className='cursor' onClick={() => setProfileModal(true)}>
                    <CardBody>
                        <CardTitle tag="h5">{data.farmer_name}</CardTitle>
                        <div className="d-flex justify-content-between">
                            <div><b>Registration No.:</b></div>
                            <div>{data.farmer_code}</div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div><b>Milk Type:</b></div>
                            <div>{data.milk_type}</div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div><b>Milk Qty:</b></div>
                            <div>{data.qty} Ltrs</div>
                        </div>
                    </CardBody>
                </Card>
            </Col>

            <Modal
                centered
                scrollable
                size="md"
                isOpen={profileModal}
                toggle={() => setProfileModal(!profileModal)}
            >
                <ModalHeader toggle={() => setProfileModal(!profileModal)}>
                    Quality Parameters
                </ModalHeader>
                <ModalBody className='bg-white'>
                    <Row>
                        <Col xs={12}>

                            <div className="d-flex justify-content-between">
                                <div><b>Qty: </b></div>
                                <div>{data.qty} Ltrs</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div><b>Fat: </b></div>
                                <div>{data.fat}</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div><b>SNF: </b></div>
                                <div>{data.snf}</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div><b>CLR: </b></div>
                                <div>{data.clr}</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div><b>Density: </b></div>
                                <div>{data.density}</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div><b>Lactose: </b></div>
                                <div>{data.lactose}</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div><b>Salts: </b></div>
                                <div>{data.salts}</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div><b>Protein: </b></div>
                                <div>{data.protein}</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div><b>Temp: </b></div>
                                <div>{data.temp}</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div><b>Added Water: </b></div>
                                <div>{data.added_water}</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div><b>Freezing Point: </b></div>
                                <div>{data.freezing_point}</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div><b>Milk Type: </b></div>
                                <div>{data.milk_type}</div>
                            </div>
                            {/*<div className="d-flex justify-content-between">
                                <div><b>Rate: </b></div>
                                <div>&#8377; {Number(data.rate).toFixed(2)} / Kg</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div><b>Total: </b></div>
                                <div>&#8377; {Number(data.total).toFixed(2)}</div>
                            </div>*/}
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    )
}

export default FarmerDailyCard;